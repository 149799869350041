#inicio {
    background-image: url(../imagenes/Home/home2.jpg);
    width: inherit;
    height: 85vh;
    text-align: center;
    background-position: center 150px;
    background-size: contain;
    background-repeat: no-repeat;
}

.inav {
    /* max-height: 180px; */
}

#ilogo {
    width: 6rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    margin-left: 4rem;
}


.navbar-brand {
    margin-right: 15rem;
}

.nav-link-button {
    color: #FFFFFF;
    margin-left: 1rem;
    margin-top: 1.2rem;
    font-size: 1rem;
}

.navbar-light .navbar-nav .nav-link {
    color: #000000;
    font-size: 1rem;
    margin-left: 1rem;
    margin-top: 1.2rem;
}


.navbar-light .navbar-nav .nav-link {
    position: relative;
    overflow: hidden;
    display: inline-block;
    text-decoration: none;
    color: #333;
}

.navbar-light .navbar-nav .nav-link:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: #009ddc;
    transition: left .8s;
}

.navbar-light .navbar-nav .nav-link:hover:after {
    left: 0;
}


#barraenlace {
    background: #ffffff;
}

.navbar {
    background: #ffffff;
    transition: all 0.6s;
}

#parrafo {
    justify-content: right;
    margin-top: 3rem;
    /*margin-right: 4rem;*/
    height: 90vh;
    overflow: hidden;
}

#inparrafo {
    width: 25%;
    margin-top: -5%;
    margin-right: 5%;
    background-color: #FFFFFF;
    padding-left: 0px;
    padding-right: 0px;
}

.nombreSeguro {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

#parrafo>div {
    align-self: center !important;
}

.card-header {
    height: 80px;
    font-size: 1.2em;
    display: table;
    font-weight: bold;
    border-top-left-radius: 0.95rem !important;
    border-top-right-radius: 0.95rem !important;
    width: 100%;
}

.card-seguro17 {
    background-color: #a0c250;
}

.card-seguro19 {
    background-color: #cc00a1;
}

.card-seguro21 {
    background-color: #cc00a1;
}

.card-seguro22 {
    background-color: #793f79;
}

.card-seguro42 {
    background-color: #ed8b32;
}

.card-seguro45 {
    background-color: #fa0205;
}

.card-seguro51 {
    background-color: #009DDC;
}
.box-new {
    margin: 0 auto;
    width: 50%;
}

.icono-estrella {
    color: white;
    margin-right: 10px; /* Esto añadirá espacio después de la estrella */
    font-size: 20px;
}

#ip1 {
    font-size: 1.2rem;
    color: #000000;
    font-weight: bold;
    /*width: 40rem;
    margin-left: -6rem;*/
}

.ip1texto {
    /*  color:#a4ce4e; */
    color: #ffc913
}

#ip2 {
    color: #009ddc;
    font-size: 3.2rem;
    /*font-weight: bold;
    width: 40rem;
    margin-left: -6rem;
    margin-top: 2rem;*/
    margin-bottom: 1rem;
}

#ip3 {
    color: #000000;
    font-weight: bold;
    margin-bottom: 1rem;
}

#ip4 {
    color: #009ddc;
    margin-bottom: 2rem;
    margin-top: 1rem;
    font-size: 3.5em;
    /*
    font-weight: bold;
    margin-left: -11rem;*/
}

.itarjetas {
    width: 35rem;
    /*width: 26rem;*/
    margin-top: 0rem;
    /*margin-left: -11rem;*/
}


.botonInicio {
    color: white;
    background: #009ddc;
    border-color: #009ddc;
    font-weight: bold;
    padding-top: 0.5em;
    padding-bottom: 0.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    overflow: hidden;
    font-size: 1em;
}

.btniniciomensaje {
    font-size: 0.5em;
}

.botonInicio:hover {
    background: white;
    border-left: white;
    border-right: white;
    border-top: white;
    color: #009ddc;
}

.botonInicio:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: #009ddc;
    transition: left .8s;
}

.botonInicio:hover:after {
    left: 0;
}

/* Estilo para capsula amarilla */
.inline-block {
    display: inline-block;
}

.bg-brand-yellow {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 201, 19, var(--tw-bg-opacity));
}

.capsula {
    border-radius: 75px;
    font-weight: 700;
    line-height: 1;
    border-radius: 3.25rem;
    /*white-space: nowrap;*/
    padding: 0.20em 0.30em
}

.bg-brand-grey {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 233, 242, var(--tw-bg-opacity));
}

.capsulaTarjeta {
    border-radius: 75px;
    font-weight: 200;
    line-height: 0;
    border-radius: 3.25rem;
    border: 2.5px solid #009ddc;
    background: #e0e9f2;
    padding: 1em 0.35em;
}


/**/
/* added */
@media (max-width: 340px) {
    #inicio {
        background-image: url(../imagenes/Home/homemobile2.jpg);
        background-position: center bottom;
        background-size: contain;
        height: 40vh;
    }

    #barraenlace {
        min-height: 50px;
    }

    #inparrafo {
        display: none;
    }

    #ilogo {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 340.02px) and (max-width: 375.98px) {
    #inicio {
        background-image: url(../imagenes/Home/homemobile2.jpg);
        background-position: center bottom;
        background-size: contain;
        height: 65vh;
    }

    #barraenlace {
        min-height: 30px;
    }

    #inparrafo {
        display: none;
    }

    #ilogo {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 376px) and (max-width: 390px) {
    #inicio {
        background-image: url(../imagenes/Home/homemobile2.jpg);
        background-position: center bottom;
        background-size: contain;
        height: 46vh;
    }

    #barraenlace {
        min-height: 30px;
    }

    #inparrafo {
        display: none;
    }

    #ilogo {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 390.02px) and (max-width: 500px) {
    #inicio {
        background-image: url(../imagenes/Home/homemobile2.jpg);
        background-position: center bottom;
        background-size: contain;
        height: 45vh;
    }

    .navbar-brand {
        margin-right: 0;
    }

    #barraenlace {
        min-height: 30px;
    }

    #inparrafo {
        display: none;
    }

    #ilogo {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 500.02px) and (max-width: 830px) {
    #inicio {
        background-image: url(../imagenes/Home/homemobile2.jpg);
        background-position: center bottom;
        background-size: contain;
        height: 65vh;
    }

    .navbar-brand {
        margin-right: 0;
    }

    #barraenlace {
        min-height: 30px;
    }

    #parrafo {
        display: none;
    }

    .card-header {
        font-size: 0.9em;
    }
}

@media (min-width: 830.02px) and (max-width: 991.98px) {
    #inicio {
        background-image: url(../imagenes/Home/home2.jpg);
        background-position: center bottom;
        background-size: contain;
        height: 33vh;
    }

    .navbar-brand {
        margin-right: 0;
    }

    #barraenlace {
        min-height: 30px;
    }

    #parrafo {
        padding-top: 10vh;
        height: 28vh;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    #inicio {
        background-image: url(../imagenes/Home/home2.jpg);
        background-position: center bottom;
        background-size: contain;
        height: 35vh;
    }

    #ip1 {
        font-size: 1.7rem;
    }

    #ip2 {
        font-size: 2.2rem;
    }

    .itarjetas {
        width: 26rem;
    }

    .navbar-light .navbar-nav .nav-link .nav-link-button {
        font-size: 0.6rem;
    }

    #parrafo {
        padding-top: 14vh;
        height: 28vh;
    }
}

@media (min-width: 1200px) and (max-width: 1299.99px) {
    #inicio {
        background-image: url(../imagenes/Home/home2.jpg);
        width: inherit;
        height: 60vh;
        text-align: center;
        background-position: center bottom;
        background-size: contain;
        background-repeat: no-repeat;
    }

    #ip2 {
        font-size: 2.8rem;
    }
}