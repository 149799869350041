.accordion-beneficios{
    width: 75%;
    margin: auto;
}
.accordion-beneficios .card{
    border-color: transparent;
}
.accordion-beneficios .card > .card-header{
    height: auto;
    background-color: white !important;
    border-bottom: transparent;
}
.accordion-beneficios .card > .card-header > .btn-link{
    color: var(--indisa-primary-color);
    text-decoration: none;
    font-size: 1rem;
    text-align: left;
}
.accordion-beneficios > .card > .card-header > .btn-link{
    font-weight: bold;
    position: relative;
    width: 100%;
    text-align: left;
}
.accordion-beneficios > .card > .card-header > .btn-link svg{
    color: var(--indisa-blue-color);
    font-size: 1.5rem;
    position: absolute;
    right: 0;
}

.nested-accordion-beneficios .card{
    border-left: solid 6px var(--indisa-green-color);
    margin-bottom: 15px;
}
.nested-accordion-beneficios .card-body{
    font-size: 1rem;
    padding-left: 30px;
}
@media (max-width: 575.98px) { 
    .accordion-beneficios{
        width: 100%;
    }
}
@media (min-width: 576px) and (max-width: 767.98px) { 
    .accordion-beneficios{
        width: 90%;
    }
}