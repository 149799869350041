.plparrafo ul
{
    list-style: none;
    padding-left: 20px;
}
.plparrafo li {
    position: relative;
    padding-left: 25px;
    margin-bottom: 8px;
}
.plparrafo li::before {
    content: "\2713";
    position: absolute;
    left: 0;
    top: 0;
    font-size: 22px;
    font-weight: bold;
    color: #1766DC;
  }
.plboton{
    background: #a4ce4e;
    font-size: 0.8rem;
    font-weight: bold;
    border-color: #a4ce4e;
}
.planesi li
{
    margin:0;
    padding:0;
}
.planesi li::marker {
    font-size: 150%;
    color: #a4ce4e;
  }
.plmedalla{
    width: 5rem;
    float: right;
    margin-right: -3.2rem;
}

.plicono{
    font-size: 1rem;
    margin-left: 1rem;
    color: #D8DAE2;
}
.coicono{
    font-size: 3rem;
    color: var(--indisa-secondary-color);
}
.coicono.active{
    color: var(--indisa-blue-color);
}
.pcomparar{
    color:#D8DAE2;
    margin: auto;
    font-style: italic;
}

.pscomparar{
    color: #a4ce4e;
    margin: auto;
    font-style: italic;
}

.pdivcomparar{
    align-items: center;
    display: flex;
    justify-content: center;
    color: var(--indisa-green-color);
    cursor: pointer;
}

.psdivcomparar{
    align-items: center;
    display: flex;
    justify-content: center;
    color: var(--indisa-green-color);
    cursor: pointer;
}

.pdivcomparar svg,
.psdivcomparar svg{
    margin-right: 10px;
    color: var(--indisa-blue-color);
    font-size: 1.5rem;
}
.psdivcomparar svg{
    border: solid 1px;
    border-radius: 50%;
    padding: 3px;
}

.plparrafo{
    font-size: 0.95em;
    height: 180px;
    white-space: break-spaces;
    text-align: justify;
}
.plparrafopie
{
    display: flex;
    font-size: 0.8em;
    height: 160px;
    align-items: center;
    text-align: initial;
}

.pluf{
    font-size: 0.75em;
}

.pejecutivo{
    color:#a4ce4e;
    font-style: italic;
    font-weight: bold;
    margin-top: -0.8rem;
}

.srplanes{
	position: relative;
}

.srplanes::after{
    content: "";
	width: 100%;
	height: 12%;
    background: #0061a0;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
}

.srnseguro{
    color: white;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    z-index: 2;

}

.detallepie{
    color: #a4ce4e;
    font-weight: bold;
}
/* Estilo para el header de las cards de planes */



.tooltip{
    background-color: #D8DAE2 !important;
    color: black !important;
}

/* formato a la tabla de comparacion  */
.compasistencia{
    align-items: center;
    display: flex;
    justify-content: center;
}

.cpasistencia{
    font-weight: bold;
    margin-bottom: 4rem;
    color: #0061a0;
}

.ctr{
    background: var(--indisa-blue-color);
    color: white;
}

.cnombreseguro{
    height: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
}

.cvalorprima{
    color: var(--indisa-secondary-color);
}

.cvalorseguro{
    color: var(--indisa-blue-color);
    font-weight: bold;  
}

.icoinfo{
    font-size: 1rem;
    color: black;
}

.cdivicon{

}

.cdivicoinfo{
    margin-left: 5rem;
    margin-top: -4rem;
}

.cpclinicas{
    color: #0061a0;
    cursor: pointer;
}

.btndescargar{
    background: #0061a0;
    color: white;
    width: 50%;
}

.btndescargar:hover{
    color: white;
    background: #0061a0;
}

.modal-backdrop.fade.show{
    opacity: 0.02;
}

.cpbtncerrar{
    cursor: pointer;
    color:#1766DC;
    height: 200px;
    width: 50%;
}

.cpbtncerrar:hover{
    background: #f2f2f2;
    transition: .3s ease all;
    border-radius: 5px;
}

.rcorners1 {
  border-radius: 90px;
}

.rounded-top-left-1 {
    border-top-left-radius: 1rem;
}
.rounded-top-right-1 {
    border-top-right-radius: 1rem;
}
.rounded-bottom-left-1 {
    border-bottom-left-radius: 1rem;
}
.rounded-bottom-right-1 {
    border-bottom-right-radius: 1rem;
}

/* Estilos a pantallas pequeñas menores de 1024px*/

@media (max-width: 1024px) {

}


/* Estilos a pantallas pequeñas menores de 768px*/

@media (max-width: 768px) {

}


/* Estilos a pantallas pequeñas menores de 600px*/

@media (max-width: 600px) {
    .plcol{
        margin-bottom: 2rem;
    }
}

/*** Ver Detalle ***/
.modal{
    background-color: rgba(0, 0, 0, 0.5);
}
.detalle-plan_header{
    background-color: #eeeeee;
    text-align: center;
    border-radius: 15px;
    padding: 20px 5px;
    margin-bottom: 40px;
}
.btn-ficha-plan{
    background-color: #eeeeee;
    width: 100%;
    border-radius: 25px;
    padding: 10px 15px;
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}
.btn-ficha-plan svg{
    font-size: 1rem;
}
.btn-ficha-plan::after{
    /* content: "\2913";
    font-weight: bold;
    font-size: 1rem; */
}

.accordion-plan .card{
    border-top-color: rgba(0, 0, 0, 0.125);
    border-right-color: rgba(0, 0, 0, 0.125);
    border-bottom-color: rgba(0, 0, 0, 0.125);
    border-left: solid 6px var(--indisa-green-color);
    margin-bottom: 15px;
}
.accordion-plan .card > .card-header{
    height: auto;
    background-color: white !important;
    border-bottom: transparent;
}
.accordion-plan .card > .card-header > .btn-link{
    color: var(--indisa-primary-color);
    text-decoration: none;
    font-size: 1rem;
    text-align: left;
}
.accordion-plan > .card > .card-header > .btn-link{
    font-weight: bold;
    position: relative;
    width: 100%;
    text-align: left;
}
.accordion-plan > .card > .card-header > .btn-link svg{
    color: var(--indisa-green-color);
    font-size: 1.5rem;
    position: absolute;
    right: 0;
}
.accordion-plan .card-body{
    padding-left: 30px;
}

/*** Comparador ***/
.table-comparacion-planes thead > tr:first-child > th{
    border-top-left-radius: 15px;    
}
.table-comparacion-planes thead > tr:first-child > td:last-child{
    border-top-right-radius: 15px;
}
.table-comparacion-planes > tbody{
    border-top: transparent !important;
}
.table-comparacion-planes > tbody > tr{
    border-color: transparent;
}
.table-comparacion-planes > tbody > tr:nth-of-type(odd) > td{
    background-color: white;
}
.table-comparacion-planes > tbody > tr:nth-of-type(even) > td{
    background-color: #F8F9FD;
}