.tabs-planes{
    justify-content: space-evenly;
    margin-top: 30px;
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: transparent;
    background-color: var(--indisa-blue-color);
    border-radius: 20px;
}
.tabs-planes .nav-link{
    font-size: 1.2rem;
    padding-left: 4rem;
    padding-right: 4rem;
    color: white;
    border-radius: 25px;
}
.tabs-planes .nav-link:hover{
    background: white !important;
    color: var(--indisa-blue-color) !important;
}
.tabs-planes .nav-link.active {
    background: white !important;
    color: var(--indisa-blue-color) !important;
}
.tab-content-planes{
    padding-top: 30px;
}
.tab-content-planes .tab-pane p{
    color: #9A9A9A;
    text-align: justify;
}
/*** Carrusel ***/
.planes-carousel .owl-nav button:hover{
    background-color: transparent !important;
}

.planes-carousel .owl-nav span{
    font-size: 60px;
    padding: 0 10px;
    color: var(--indisa-green-color);
}

.planes-carousel .owl-nav .owl-prev{
    position: absolute;
    right: 100%;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.planes-carousel .owl-nav .owl-next{
    position: absolute;
    left: 100%;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media (max-width: 575.98px) { 
    .planes-carousel .owl-nav .owl-prev{
        left: -15px;  
        right: inherit;      
    }
    .planes-carousel .owl-nav .owl-next{
        right: -15px;
        left: inherit;      
    }
}
@media (min-width: 576px)  and (max-width: 767.98px) { 
    .planes-carousel .owl-nav .owl-prev{        
        right: 99%;      
    }
    .planes-carousel .owl-nav .owl-next{
        left: 99%;     
    }
}
@media (min-width: 992px)  and (max-width: 1199.98px) { 
    .planes-carousel .owl-nav .owl-prev{        
        right: 99%;      
    }
    .planes-carousel .owl-nav .owl-next{
        left: 99%;     
    }
}
/* Carrusel de Slick */
.slick-prev {
    left: -30px !important;
}
.slick-prev:before{
    content: "\2039" !important;
}
.slick-next:before{
    content: "\203A" !important;
}
.slick-prev:before,
.slick-next:before{
    color: var(--indisa-green-color) !important;
    font-size: 50px !important;
    font-weight: bold !important;
    padding-right: 5px !important;
    padding-left: 5px !important;
}

/*** Plan ***/
.item-plan{
    /*padding-right: 20px;*/
    padding: 0 10px;
}
.item-plan > .card{    
    border: solid 1px rgba(0, 0, 0, 0.125);
    border-top: transparent;
    border-radius: 0.95rem;
}
.item-plan .card-header{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    font-size: 1.2rem;
    border-radius: 0.95rem;
}
.item-plan .card-body{
    font-size: 1rem;
}

.item-plan .card-title{
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold;
    color: var(--indisa-blue-color);
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.item-plan .card-body ul{
    font-size: 0.85rem;
    list-style: none;
}
.item-plan .card-body ul > li{
    margin-bottom: 10px;
    position: relative;
}
.item-plan .card-body ul > li::before{    
    content: "\2713";
    color: #1766dc;
    font-size: 11px;
    font-weight: 700;    
    position: absolute;
    left: -30px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border: solid 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
    width: 18px;
    border-radius: 50%;
}
.item-plan .card-text{
    min-height: 300px;
}
@media (min-width: 576px) and (max-width: 767.98px) { 
    .item-plan .card-text{
        min-height: 400px;
    }
}

.btn-comprar-plan{
    display: block;
    background: var(--indisa-blue-color);
    font-size: 1rem;
    color: white !important;
    border-radius: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.btn-detalle-plan,
.btn-comparar-plan{
    display: block;
    width: 100%;
    background: var(--indisa-green-color);
    font-size: 1rem;
    color: white !important;
    border-radius: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    /*margin-bottom: 20px;*/
}
.btn-comparar-plan.inner{
    font-size: inherit;
    padding-top: 6px;
    padding-bottom: 6px;
}
.btn-comprar-plan:hover,
.btn-detalle-plan:hover,
.btn-comparar-plan:hover{
    opacity: 0.8;
}

/* Modal Plan */
.modal-plan{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background-color: rgba(0, 0, 0, 0.5);
}
.modal-plan_content{
    background-color: white;
    padding: 20px;
}
.btn-cerrar-modal-plan{
    background-color: transparent;
    border: transparent;
    font-size: 2rem;
}
.modal-plan_header{
    text-align: right;
    margin-bottom: 10px;
}