:root {
  --indisa-primary-color: #212529;
  --indisa-secondary-color: #9A9A9A;
  --indisa-blue-color: #20A5DD;
  --indisa-green-color: #A4CE4E;
}

body {
    font-family: 'Heroic Condensed Regular','Futura Std Book' ,'Noto Sans', 'Arial', 'Helvetica', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;  
    /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
  }

/* boton secciones: inicio, proceso contracion, testimonios  */

.boton{
    color: white;
    background: #009ddc;
    border-color:#009ddc;
    margin: 2rem 0;
    font-weight: bold;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
  
  }
  
  .boton:hover{
    border-bottom: 3px solid #009ddc;
    border-left: white;
    border-right: white;
    border-top: 3px solid #009ddc;
    background-color: white;
    color: #009ddc;
  }

  /* fondo Accordion, seccion todos nos preguntan  */

.tnp{
  background-color: #f5f5f5;
}

  .tnpaccordion{
    text-align: center;
  }

.accordion-button{
  background-color: #f5f5f5;
}

.accordion-button:not(.collapsed) {
  background-color: #f5f5f5;
}

.accordion-body {
  background-color: #f5f5f5;
  text-align: center;
}

.tnpnombre{
  color: black;
  font-weight: bold;
}

  .accordion-button::after{
    background-image: url(./imagenes/Testimonios/iconomasc.png);
  } 
  
  .accordion-button:not(.collapsed)::after{
    background-image: url(./imagenes/Testimonios/iconomenos.png);
    transform: rotate(0);
  }

/* Added: Todos nos preguntan */
#tnp .accordion-body{ text-align: justify; }

#sr,
#cf,
#bc{
  scroll-margin-top: 130px;
}

    /* Estilos a pantallas pequeñas menores de 600px*/

@media (max-width: 600px) {

    .fondoFaqs{
      font-size: 11px; 
    }
  
    .tnpnombre{
      font-size: 11px;
    }

    .accordion-body {
      font-size: 11px;
    }
  }

.titulos-v1{
  color: var(--indisa-blue-color);
  font-weight: bold;
  margin-bottom: 2rem;
}
.text-blue{
  color: var(--indisa-blue-color) !important;
}
.text-green{
  color: var(--indisa-green-color) !important;
}
.text-primary{
  color: var(--indisa-primary-color) !important;
}
.text-secondary{
  color: var(--indisa-secondary-color) !important;
}