.item-beneficio img{
    border-radius: 20px;
}

.beneficios-carousel .owl-nav button:hover{
    background-color: transparent !important;
}

.beneficios-carousel .owl-nav span{
    font-size: 60px;
    padding: 0 10px;
    color: var(--indisa-green-color);
}

.beneficios-carousel .owl-nav .owl-prev{
    position: absolute;
    right: 100%;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.beneficios-carousel .owl-nav .owl-next{
    position: absolute;
    left: 100%;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media (max-width: 575.98px) { 
    .beneficios-carousel .owl-nav .owl-prev{
        left: 0;  
        right: inherit;      
    }
    .beneficios-carousel .owl-nav .owl-next{
        right: 0;
        left: inherit;      
    }
}