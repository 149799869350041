.tabs-cf{
    justify-content: space-between;
    margin-top: 30px;
    padding-bottom: 20px;
    border-bottom: solid 1px var(--indisa-green-color);
}
.tabs-cf .nav-link{
    font-size: 1.2rem;
    padding-left: 5rem;
    padding-right: 5rem;
    color: var(--indisa-primary-color);
    border-radius: 25px;
}
.tabs-cf .nav-link:hover{
    background: #CDCDCD !important;
}
.tabs-cf .nav-link.active {
    background: #CDCDCD !important;
    color: var(--indisa-primary-color) !important;
}
.tab-content-cf{
    padding-top: 30px;
}
.tab-content-cf .tab-pane p{
    color: #9A9A9A;
    text-align: justify;
}

.table-cf-wrapper{
    background-color: #F6F7FB;
    padding: 20px;
    border-radius: 15px;
}
.table-cf{
    width: 100%;
}
.table-cf tr > td{
    padding-top: 15px;
    padding-bottom: 15px;
}
.table-cf tr > td:first-child{
    width: 80%;
    font-size: 1rem;
}
.table-cf tr > td:first-child > span{
    font-size: 0.9rem;
    color: var(--indisa-blue-color);
}
.table-cf tr > td:last-child{
    width: 20%;
    text-align: right;
    font-size: 1.2rem;
}

.table-cf tr > td:last-child{
    padding-right: 10px;
}
.table-cf tr > td:last-child.monto-destacado{
    padding-right: 0;
}
.table-cf tr > td:last-child > span{
    background: #E9EAEC;
    padding: 10px 10px;
    border-radius: 20px;
}
.table-cf tr > td:last-child > span.ahorraste{
    background: #CBE19F;
}

.table-cf tr.line{
    border-top: solid 1px;
}
.table-cf tr.line > td{
    padding-top: 30px;
}
