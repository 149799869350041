#ft,#ftlegal{
    background: #009ddc;
    /*height: 20rem;*/
    color: white;
    padding-bottom: 1rem; /* added */
}

.divft{
margin: 2rem;
}

.textoft{
    text-align: justify;
}


.footer{
    display: flex;
    margin-top: 1rem;
    margin-left: 10rem;
    margin-bottom: 2rem;
}

.fcorreo{
    margin-top: 2rem;
}

.ftcorreo{
    color: #0a58ca;
    font-weight: bold;

}

.fdivlogo{
    margin-top: 5rem;
}

.flogo{
    width: 10rem;
}

.fdivenlaces{
    margin-left: 4rem;
    line-height: .2; 
}

.fdivenlace{
    display: flex; 
    margin-top: 5rem;
    font-size: 12px;
    color: #0061a0;
}

.ficono{
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    font-size: 0.4rem;
    color: black;
}

.fdivp1{
    display: flex;
}

.fdivp2{
    display: flex;
}

.fenlaces{
    text-decoration: none;
}

a.fenlaces{
    color:#004269;
    font-weight: bold;
}

.fdivinfo{
    font-size: 12px;
}

.fdivenlacesinternos{
    display: flex;
    margin-top: 2rem;
}


        /* Estilos a pantallas pequeñas menores de 600px*/

@media (max-width: 600px) {


.divft{
    font-size: 9px;
}

.footer{
    margin-left: 0rem;
    display: inline;
}
.fdivlogo{
    margin-top: 1rem;
    text-align: center;
}

.fdivenlaces{
    margin-left: 0.5rem;
    line-height:inherit;
    margin-top: -3rem;
}

.fdivenlace{
    display: inline;
    font-size: 11px;
    line-height: 1.2;
}

.fdivp1{
    margin-top: 4rem;
}

.fdivinfo{
    font-size: 10px;
    line-height: 1.5;
    width: 95%;
}

.fdivenlacesinternos{
    font-size: 10.5px;
    margin-top:0;

}

.fdivtextolegal{
    font-size: 12.5px;
    
    text-align: center;

}


.ficono{
    margin-right: 0.1rem;
}

}

/* added */
@media (max-width: 767.98px){
    .fdivenlace {
        flex-direction: column;
    }
    
}


